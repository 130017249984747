<template>
  <div class="flex">
    <div
      style="
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 300px;
      "
    >
      <v-btn
        href="https://menu.actsch24.ru/"
        target="_blank"
        style="color: white; background-color: #0050b8; margin-top: 10px"
        text
      >
        СВОД ПО КЛАССАМ &nbsp;
        <svg
          width="14px"
          height="14px"
          viewBox="0 0 24.00 24.00"
          id="meteor-icon-kit__regular-external-link"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#000000"
          stroke-width="0.00024000000000000003"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
              fill="white"
            ></path>
          </g>
        </svg>
      </v-btn>
      <label for="className"
        >Необходимо выбрать первый день недели, которую хотите изменять</label
      >
      <input
        type="date"
        id="Date"
        class="form-control"
        required
        v-model="dateInput"
      />
      <button @click="getNewWeek()" id="createReciept" class="btn btn-primary">
        Отобразить неделю
      </button>
    </div>
    <div style="width: 90%">
      <table class="reciept-table">
        <thead>
          <tr>
            <th>Дата</th>
            <th>День</th>
            <th>Блюдо 1</th>
            <th>Блюдо 2</th>
            <th>Блюдо 3</th>
            <th>Блюдо 4</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(day, index) in tableData"
            :key="index"
            :class="{
              'now-day':
                new Date(day.date).toLocaleDateString() ==
                new Date().toLocaleDateString(),
            }"
          >
            <td>{{ new Date(day.date).toLocaleDateString() }}</td>
            <td>{{ day.day }}</td>
            <td>
              <textarea
                contentEditable
                rows="1"
                class="form-control"
                type="text"
                v-model="day.bludo1.name"
              />
              <div>
                Классы:
                <a id="classCount" @click="showClass(day.bludo1.class)">{{
                  day.bludo1.class.length
                }}</a>
              </div>
              <div>
                Заказы: <b>{{ day.bludo1.count }}</b>
              </div>
            </td>
            <td>
              <textarea
                contentEditable
                rows="1"
                class="form-control"
                type="text"
                v-model="day.bludo2.name"
              />
              <div>
                Классы:
                <a id="classCount" @click="showClass(day.bludo2.class)">{{
                  day.bludo2.class.length
                }}</a>
              </div>
              <div>
                Заказы: <b>{{ day.bludo2.count }}</b>
              </div>
            </td>
            <td>
              <textarea
                contentEditable
                rows="1"
                class="form-control"
                type="text"
                v-model="day.bludo3.name"
              />
              <div>
                Классы:
                <a id="classCount" @click="showClass(day.bludo3.class)">{{
                  day.bludo3.class.length
                }}</a>
              </div>
              <div>
                Заказы: <b>{{ day.bludo3.count }}</b>
              </div>
            </td>
            <td>
              <textarea
                contentEditable
                rows="1"
                class="form-control"
                type="text"
                v-model="day.bludo4.name"
              />
              <div>
                Классы:
                <a id="classCount" @click="showClass(day.bludo4.class)">{{
                  day.bludo4.class.length
                }}</a>
              </div>
              <div>
                Заказы: <b>{{ day.bludo4.count }}</b>
              </div>
            </td>
            <td>
              <button
                v-if="!day.check"
                @click="createDay(day)"
                id="createReciept"
                style="margin: 0"
                class="btn btn-success btn-sm"
              >
                Создать день
              </button>

              <button
                v-if="day.check"
                @click="updateDay(day)"
                id="createReciept"
                style="margin: 0"
                class="btn btn-success btn-sm"
              >
                Обновить
              </button>
              <br v-if="day.check" />
              <button
                v-if="day.check"
                @click="deleteDay(day)"
                id="createReciept"
                style="margin: 0"
                class="btn btn-danger btn-sm"
              >
                Удалить
              </button>
              <br />
              <button
                v-if="!day.check"
                @click="createDayOff(day)"
                id="createReciept"
                style="margin: 0"
                class="btn btn-primary btn-sm"
              >
                Создать выходной
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog v-model="showClassDialog.active" width="590px">
      <v-card>
        <v-card-text style="color: black; font-size: 20px">
          <!-- Заявка на {{ new Date(tomorrowEating.data.date).toLocaleDateString }} -->
        </v-card-text>
        <v-card-actions
          v-if="showClassDialog.data"
          style="display: flex; flex-wrap: wrap"
        >
          <table class="reciept-table">
            <thead>
              <tr>
                <th>Класс</th>
                <th>Заказы</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in showClassDialog.data" :key="index">
                <td>{{ item.className }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </tbody>
          </table>
          <v-btn
            style="margin-top: 10px"
            color="primary"
            block
            @click="showClassDialog.active = false"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const week = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Cб"];
import TutorialDataService from "../services/TutorialDataService";
export default {
  data() {
    return {
      dateFromServer: "",
      dateInput: "",
      tableData: [],
      showClassDialog: { active: false, data: [] },
    };
  },
  methods: {
    async init() {
      await this.getDate();
      await this.getNewWeek();
      // await this.getTableData();
    },
    async getDate() {
      await TutorialDataService.getDateWorld()
        .then((response) => {
          this.dateFromServer = response.data;
          this.dateInput = new Date(this.dateFromServer)
            .toISOString()
            .slice(0, 10);
        })
        .catch((e) => {
          alert("ошибка получения даты");
          console.log(e);
        });
    },

    getNewWeek() {
      this.tableData = [];
      let tempDate = Date.parse(this.dateInput) - 86400000;
      for (let i = 0; i < 6; i++) {
        tempDate = tempDate + 86400000;
        this.tableData.push({
          date: new Date(tempDate),
          bludo1: { name: "", class: [], count: 0 },
          bludo2: { name: "", class: [], count: 0 },
          bludo3: { name: "", class: [], count: 0 },
          bludo4: { name: "", class: [], count: 0 },
          day: week[new Date(tempDate).getDay()],
        });
      }

      this.getDay();
    },

    async createDay(data) {
      TutorialDataService.createDayWeekMenu(data)
        .then(() => {
          this.getDay();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async deleteDay(data) {
      await TutorialDataService.deleteDayWeekMenu(data)
        .then(() => {
          this.getNewWeek();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async createDayOff(day) {
      let data = {
        day: "ВЫХОДНОЙ",
        date: day.date,
      };
      TutorialDataService.createDayWeekMenu(data)
        .then(() => {
          this.getDay();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async updateDay(data) {
      let id = data._id;
      data.day = week[new Date(data.date).getDay()];
      console.log(data);
      TutorialDataService.updateDayWeekMenu(id, data)
        .then(() => {
          this.getDay();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async getDay() {
      let data = {
        dateBefore: this.tableData[this.tableData.length - 1].date,
        dateFrom: this.tableData[0].date,
      };
      await TutorialDataService.findDayWeekMenuByDateRange(data)
        .then((response) => {
          let temp = Object.values(response.data);

          for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < this.tableData.length; j++) {
              if (
                new Date(temp[i].date).toLocaleDateString() ==
                new Date(this.tableData[j].date).toLocaleDateString()
              ) {
                this.tableData[j] = temp[i];

                this.tableData[j].check = true;
                if (temp[i].day != "ВЫХОДНОЙ") {
                  this.countPortions(j);
                } else {
                  this.tableData[j].bludo1 = { name: "", class: [], count: 0 };
                  this.tableData[j].bludo2 = { name: "", class: [], count: 0 };
                  this.tableData[j].bludo3 = { name: "", class: [], count: 0 };
                  this.tableData[j].bludo4 = { name: "", class: [], count: 0 };
                }

                this.$forceUpdate();
              }
            }
          }
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
    countPortions(i) {
      this.tableData[i].bludo1.count = 0;
      for (let j = 0; j < this.tableData[i].bludo1.class.length; j++) {
        this.tableData[i].bludo1.count += Number(
          this.tableData[i].bludo1.class[j].count
        );
      }
      this.tableData[i].bludo2.count = 0;
      for (let j = 0; j < this.tableData[i].bludo2.class.length; j++) {
        this.tableData[i].bludo2.count += Number(
          this.tableData[i].bludo2.class[j].count
        );
      }
      this.tableData[i].bludo3.count = 0;
      for (let j = 0; j < this.tableData[i].bludo3.class.length; j++) {
        this.tableData[i].bludo3.count += Number(
          this.tableData[i].bludo3.class[j].count
        );
      }
      this.tableData[i].bludo4.count = 0;
      for (let j = 0; j < this.tableData[i].bludo4.class.length; j++) {
        this.tableData[i].bludo4.count += Number(
          this.tableData[i].bludo4.class[j].count
        );
      }
    },
    showClass(data) {
      this.showClassDialog.data = data;
      this.showClassDialog.data.sort((a, b) =>
        a.className > b.className ? 1 : -1
      );
      this.showClassDialog.active = true;
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style scoped>
textarea {
  min-height: 60px;
  overflow-y: auto;
  word-wrap: break-word;
}
.now-day {
  background-color: aquamarine;
}
#classCount {
  font-weight: 500;
  color: blue;
}
</style>
